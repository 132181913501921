export function tradeInDiscountStatus(status) {
    switch (status) {
        case 'SUBMITTED':
            return 'Wysłany';
        case 'APPROVED':
        case 'ACCEPTED':
            return 'Zaakceptowany';
        case 'REJECTED':
            return 'Odrzucony';
        case 'SETTLED':
            return 'Rozliczony';
        case 'CORRECTED':
            return 'Skorygowany';
        default:
            return status;
    }
}
;
