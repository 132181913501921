import { tradeInDiscountStatus } from 'common/formatters/tradeInDiscountStatus';
import { shortPartyDescription } from 'common/party';
import React from 'react';
export function TradeInDiscountApplicationEvent(_a) {
    var event = _a.event;
    return (React.createElement("div", { className: "well well-sm" },
        React.createElement("span", null, event.payload.number),
        React.createElement("span", null,
            " dla ",
            shortPartyDescription(event.payload.party)),
        React.createElement("span", null,
            " na ",
            React.createElement("b", null, event.payload.carCommonOrderNumber),
            "."),
        React.createElement("span", null,
            " Status: ",
            React.createElement("b", null, tradeInDiscountStatus(event.payload.status)))));
}
